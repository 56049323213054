@font-face {
  font-family: 'Avenir';
  src: url('./fonts/avenir.woff2') format('woff2'), url('./fonts/avenir.woff') format('woff');
  font-style: normal;
}

.layout {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.layoutZone {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  pointer-events: auto;
}

.layoutZoneBottomLeft {
  margin-top: 10px;
}

@media screen and (max-height: 548px) {
  .layoutZoneBottomLeft {
    flex-direction: row;
  }

  .layoutZoneBottomLeft > div > button {
    margin-right: 15px;
  }
}

.layoutZone > button {
  flex-shrink: 0;
}

.layoutZoneTopRight {
  position: absolute;
  top: 15px;
  right: 15px;
}

.layoutZoneTopRight > div,
.layoutZoneBottomLeft > div {
  margin-bottom: 15px;
}

.layoutZoneTopRight > div:last-child,
.layoutZoneBottomLeft > div:last-child {
  margin-bottom: 0;
}

.layoutZoneTopLeft {
  position: absolute;
  top: 15px;
  left: 15px;
}

.layoutZoneBottomLeft {
  position: absolute;
  top: 360px;
  left: 65px;
}

.hiddenZoneContainer {
  display: none;
}
